import { AbChartBase } from './base/ab-chart.base';
import { AbChartTypeEnum } from '../enums/ab-chart-type.enum';

/**
 * Bar chart
 *
 * @export
 * @class AbBarChart
 * @extends {AbChartBase}
 */
export class AbBarChart extends AbChartBase {

    /////////////////////////////////////////////////////
	//#region Properties
    /////////////////////////////////////////////////////

    /**
     * Chart type
     *
     * @abstract
     * @type {AbChartTypeEnum}
     * @memberof AbBarChart
     */
    public type: AbChartTypeEnum = AbChartTypeEnum.Bar;

    //#endregion

    /////////////////////////////////////////////////////
	//#region Initialize
    /////////////////////////////////////////////////////

    /**
     * Creates an instance of AbBarChart.
     * @param {string} containerId Container to render chart
     * @param {string} chartId Chart id
     * @memberof AbBarChart
     */
    constructor(containerId: string, chartId: string) {
        super(containerId, chartId);

        this.objChart.type = this.type;
    }

    //#endregion

    /////////////////////////////////////////////////////
	//#region Public methods
    /////////////////////////////////////////////////////

    /**
     * Use horizontal bar
     *
     * @memberof AbBarChart
     */
    public useHorizontalBar(): void {
        this.objChart.type = AbChartTypeEnum.BarHorizontal;
    }

    //#endregion

}