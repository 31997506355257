import {
    Chart,
    ChartOptions,
    ChartDataSets,
    ChartData,
    ChartLegendOptions,
    ChartTitleOptions,
    ChartTooltipOptions,
    ChartAnimationOptions,
    ChartLayoutOptions
} from 'chart.js';
import { AbChartTypeEnum } from '../../enums/ab-chart-type.enum';
import { IAbChartModel } from '../../models/ab-chart.model';

/**
 * Chart base class
 *
 * @export
 * @abstract
 * @class AbChartBase
 */
export abstract class AbChartBase {

    /////////////////////////////////////////////////////
	//#region Properties
    /////////////////////////////////////////////////////

    /**
     * Chart type
     *
     * @abstract
     * @type {AbChartTypeEnum}
     * @memberof AbChartBase
     */
    public abstract type: AbChartTypeEnum;

    /**
     * Container to render chart
     *
     * @type {string}
     * @memberof AbChartBase
     */
    public containerId: string;

    /**
     * Chart identifier
     *
     * @type {string}
     * @memberof AbChartBase
     */
    public chartId: string;

    /**
     * Chartjs object
     *
     * @type {Chart}
     * @memberof AbChartBase
     */
    public objChartJS: Chart = null;

    /**
     * Chart object model
     *
     * @type {IAbChartModel}
     * @memberof AbChartBase
     */
    public objChart: IAbChartModel = {} as IAbChartModel;

    /**
     * Height chart
     *
     * @type {number}
     * @memberof AbChartBase
     */
    public height: number;

    /**
     * Width chart
     *
     * @type {number}
     * @memberof AbChartBase
     */
    public width: number;

    //#endregion

	/////////////////////////////////////////////////////
	//#region Getters and Setters
    /////////////////////////////////////////////////////

    /**
     * Get chart data
     *
     * @readonly
     * @type {ChartData}
     * @memberof AbChartBase
     */
    public get Data(): ChartData {
        if (!this.objChart.data) {
            this.objChart.data = {} as ChartData;
        }

        return this.objChart.data;
    }

    /**
     * Set chart data
     *
     * @memberof AbChartBase
     */
    public set Data(value: ChartData) {
        this.objChart.data = { ...this.Data, ...value } as ChartData;
    }

    /**
     * Get options chart
     *
     * @readonly
     * @type {ChartOptions}
     * @memberof AbChartBase
     */
    public get Options(): ChartOptions {
        if (!this.objChart.options) {
            this.objChart.options = {} as ChartOptions;
        }

        return this.objChart.options;
    }

    /**
     * Set optios chart
     *
     * @memberof AbChartBase
     */
    public set Options(value: ChartOptions) {
        this.objChart.options = { ...this.Options, ...value } as ChartOptions;
    }

    //#endregion

    /////////////////////////////////////////////////////
	//#region Initialize
    /////////////////////////////////////////////////////

    /**
     * Creates an instance of AbChartBase.
     * @param {string} containerId Container to render chart
     * @param {string} chartId Chart id
     * @memberof AbChartBase
     */
    constructor(containerId: string, chartId: string) {
        this.containerId = containerId;
        this.chartId = chartId;

        this.emptyContainer();
    }

    //#endregion

    /////////////////////////////////////////////////////
	//#region Public methods
    /////////////////////////////////////////////////////

    /**
     * Set chart size
     *
     * @param {number} width Width
     * @param {number} height Height
     * @memberof AbChartBase
     */
    public setSize(width: number, height: number): void {
        this.width = width;
        this.height = height;
    }

    /**
     * Set responsive chart
     * Default: true
     *
     * @param {boolean} responsive Responsive
     * @memberof AbChartBase
     */
    public setResponsive(responsive: boolean): void {
        this.Options.responsive = responsive;
    }

    /**
     * Add data to chart
     *
     * @param {ChartData} objData Data model
     * @memberof AbChartBase
     */
    public addData(objData: ChartData): void {
        this.Data = objData;
    }

    /**
     * Add chart options
     *
     * @param {IAbChartOptionsModel} objOptions Options
     * @memberof AbChartBase
     */
    public addOptions(objOptions: ChartOptions): void {
        this.Options = objOptions;
    }

    /**
     * Add dataset to chart
     *
     * @param {ChartDataSets} objDataset Dataset model
     * @memberof AbChartBase
     */
    public addDataset(objDataset: ChartDataSets): void {
        this.Data.datasets.push(objDataset);
    }

    /**
     * Add legend to chart
     *
     * @param {ChartLegendOptions} objLegend Legend object
     * @memberof AbChartBase
     */
    public addLegend(objLegend: ChartLegendOptions): void {
        this.Options.legend = objLegend;
    }

    /**
     * Add title chart
     *
     * @param {ChartTitleOptions} objTitle Title object
     * @memberof AbChartBase
     */
    public addTitle(objTitle: ChartTitleOptions): void {
        this.Options.title = objTitle;
    }

    /**
     * Add tootips chart
     *
     * @param {ChartTooltipOptions} objTooltips Tooltips object
     * @memberof AbChartBase
     */
    public addTooltips(objTooltips: ChartTooltipOptions): void {
        this.Options.tooltips = objTooltips;
    }

    /**
     * Add animation chart
     *
     * @param {ChartAnimationOptions} objAnimation Animation object
     * @memberof AbChartBase
     */
    public addAnimation(objAnimation: ChartAnimationOptions): void {
        this.Options.animation = objAnimation;
    }

    /**
     * Add layout chart
     *
     * @param {ChartLayoutOptions} objLayout
     * @memberof AbChartBase
     */
    public addLayout(objLayout: ChartLayoutOptions): void {
        this.Options.layout = objLayout;
    }

    /**
     * Chart render
     *
     * @memberof AbChartBase
     */
    public render(): void {
        let canvas = this.createCanvas();
        this.objChartJS = new Chart(canvas, this.objChart);
    }

    /**
     * Chart destroy
     *
     * @memberof AbChartBase
     */
    public destroy(): void {
        if (this.objChartJS !== null) {
            this.objChartJS.destroy();
        }        
    }

    /**
     * Empty container
     *
     * @memberof AbChartBase
     */
    public emptyContainer(): void {
        let container = document.getElementById(this.containerId) as HTMLDivElement;
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }
    }
    
    //#endregion

    /////////////////////////////////////////////////////
	//#region Private methods
    /////////////////////////////////////////////////////
    
    /**
     * Create canvas element to chart
     *
     * @private
     * @returns {HTMLCanvasElement}
     * @memberof AbChartBase
     */
    private createCanvas(): HTMLCanvasElement {
        let container = document.getElementById(this.containerId) as HTMLDivElement;
        let canvas = document.createElement('canvas') as HTMLCanvasElement;

        canvas.setAttribute('id', this.chartId);
        if (this.width) {
            canvas.setAttribute('width', this.width.toString());
        }

        if (this.height) {
            canvas.setAttribute('height', this.height.toString());
        }

        container.append(canvas);
        return canvas;
    }

    //#endregion
}