import { AbChartBase } from './base/ab-chart.base';
import { AbChartTypeEnum } from '../enums/ab-chart-type.enum';

/**
 * Radar chart
 *
 * @export
 * @class AbRadarChart
 * @extends {AbChartBase}
 */
export class AbRadarChart extends AbChartBase {

    /////////////////////////////////////////////////////
	//#region Properties
    /////////////////////////////////////////////////////

    /**
     * Chart type
     *
     * @abstract
     * @type {AbChartTypeEnum}
     * @memberof AbRadarChart
     */
    public type: AbChartTypeEnum = AbChartTypeEnum.Radar;

    //#endregion

    /////////////////////////////////////////////////////
	//#region Initialize
    /////////////////////////////////////////////////////

    /**
     * Creates an instance of AbRadarChart.
     * @param {string} containerId Container to render chart
     * @param {string} chartId Chart id
     * @memberof AbRadarChart
     */
    constructor(containerId: string, chartId: string) {
        super(containerId, chartId);

        this.objChart.type = this.type;
    }

    //#endregion

}