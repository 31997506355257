import { AbChartBase } from './base/ab-chart.base';
import { AbChartTypeEnum } from '../enums/ab-chart-type.enum';

/**
 * Pie chart
 *
 * @export
 * @class AbPieChart
 * @extends {AbChartBase}
 */
export class AbPieChart extends AbChartBase {

    /////////////////////////////////////////////////////
	//#region Properties
    /////////////////////////////////////////////////////

    /**
     * Chart type
     *
     * @abstract
     * @type {AbChartTypeEnum}
     * @memberof AbPieChart
     */
    public type: AbChartTypeEnum = AbChartTypeEnum.Pie;

    //#endregion

    /////////////////////////////////////////////////////
	//#region Initialize
    /////////////////////////////////////////////////////

    /**
     * Creates an instance of AbPieChart.
     * @param {string} containerId Container to render chart
     * @param {string} chartId Chart id
     * @memberof AbPieChart
     */
    constructor(containerId: string, chartId: string) {
        super(containerId, chartId);

        this.objChart.type = this.type;
    }

    //#endregion

    /////////////////////////////////////////////////////
	//#region Public methods
    /////////////////////////////////////////////////////

    /**
     * Set to doughnut chart
     *
     * @memberof AbPieChart
     */
    public useDoughnut(): void {
        this.objChart.type = AbChartTypeEnum.Doughnut;
    }

    //#endregion

}