import { AbChartBase } from './base/ab-chart.base';
import { AbChartTypeEnum } from '../enums/ab-chart-type.enum';

/**
 * Scatter chart
 *
 * @export
 * @class AbScatterChart
 * @extends {AbChartBase}
 */
export class AbScatterChart extends AbChartBase {

    /////////////////////////////////////////////////////
	//#region Properties
    /////////////////////////////////////////////////////

    /**
     * Chart type
     *
     * @abstract
     * @type {AbChartTypeEnum}
     * @memberof AbScatterChart
     */
    public type: AbChartTypeEnum = AbChartTypeEnum.Scatter;

    //#endregion

    /////////////////////////////////////////////////////
	//#region Initialize
    /////////////////////////////////////////////////////

    /**
     * Creates an instance of AbScatterChart.
     * @param {string} containerId Container to render chart
     * @param {string} chartId Chart id
     * @memberof AbScatterChart
     */
    constructor(containerId: string, chartId: string) {
        super(containerId, chartId);

        this.objChart.type = this.type;
    }

    //#endregion

}